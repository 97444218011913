import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Grid,
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import i18n from "i18next";
import moment from "moment";
import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import { UserCardDetail } from "./UserCardDetail";
import { getProductPrices, getUserCardDetail } from "../../../api/premium";
import { config } from "../../../config";
import { CrossIcon } from "../../../styles/Icons";
import { getCurrencySymbol } from "../../../utils/getCurrencySymbol";

const neutralizeBack = (callback) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
    callback();
  };
};

const revivalBack = () => {
  window.onpopstate = undefined;
  window.history.back();
};

class Profile extends React.Component {
  constructor(properties) {
    super(properties);
    if (
      properties.data !== undefined &&
      Object.keys(properties.data).length !== 0
    ) {
      let profession = "";
      let professionAutre = "";
      if (
        properties.data.userdata.pro &&
        properties.data.userdata.proLabel !== undefined
      ) {
        if (
          properties.data.userdata.proLabel !== null &&
          properties.data.userdata.proLabel !== `Médecin` &&
          properties.data.userdata.proLabel !== `Chirurgien-Dentiste` &&
          properties.data.userdata.proLabel !== `Pharmacien` &&
          properties.data.userdata.proLabel !== `Sage-Femme` &&
          properties.data.userdata.proLabel !== `Autre`
        ) {
          profession = "Autre";
          professionAutre = properties.data.userdata.proLabel;
        } else {
          profession = properties.data.userdata.proLabel;
        }
      }
      this.state = {
        data: {
          connected:
            properties.data.connected !== undefined
              ? properties.data.connected
              : false,
          email:
            properties.data.userdata.email !== undefined
              ? properties.data.userdata.email
              : "",
          abonnement:
            properties.data.userdata.periode !== undefined
              ? properties.data.userdata.periode
              : "",
          dateFin:
            properties.data.userdata.dateFin !== undefined
              ? properties.data.userdata.dateFin
              : "",
          premium:
            properties.data.userdata.dateFin !== undefined &&
            properties.data.userdata.dateFin !== null
              ? moment(properties.data.userdata.dateFin).isSameOrAfter()
              : false,
          pro:
            properties.data.userdata.pro !== undefined
              ? properties.data.userdata.pro
              : false,
          cancelAt:
            properties.data.userdata.cancelAt !== undefined
              ? properties.data.userdata.cancelAt
              : "",
          profession,
          professionAutre,
          oldPW: "",
          newPW: "",
          newPWconfirm: "",
          declarationList:
            properties.data.declarationList !== undefined
              ? properties.data.declarationList
              : [],
        },
        activePage: 0,
        passwordUpdate: false,
        roleUpdate: false,
        msgDisplay: false,
        msgPWDisplay: false,
        displayUnsub: false,
        displayReviveConfirmation: false,
        subscriptionDetails: {
          yearly: { price: null, currency: null },
          monthly: { price: null, currency: null },
        },
        userCardDetail: [],
        showPaymentDetails: false,
      };
    } else {
      this.state = {
        data: {
          connected: false,
          email: "",
          premium: false,
          abonnement: "",
          pro: false,
          profession: "",
          professionAutre: "",
          oldPW: "",
          newPW: "",
          newPWconfirm: "",
          declarationList: [],
        },
        activePage: 0,
        passwordUpdate: false,
        msgDisplay: false,
        roleUpdate: false,
        msgPWDisplay: false,
        displayUnsub: false,
        displayReviveConfirmation: false,
        subscriptionDetails: {
          yearly: { price: null, currency: null },
          monthly: { price: null, currency: null },
        },
        userCardDetail: [],
        showPaymentDetails: false,
      };
    }

    this.handlePage = this.handlePage.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
    this.handleUnsubscribe = this.handleUnsubscribe.bind(this);
    this.handleReviveSubscription = this.handleReviveSubscription.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.validateUnsubscribe = this.validateUnsubscribe.bind(this);
    this.validateReviveSubscription =
      this.validateReviveSubscription.bind(this);

    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.fetchSubscriptionPrice();
    this.fetchUserCardDetail();
  }

  componentDidUpdate(pP, pS) {
    if (
      this.props.msg !== null &&
      this.props.msg !== pP.msg &&
      this.state.msgDisplay === false
    ) {
      this.setState({ msgDisplay: true });
    }
    if (
      this.props.msgPW !== null &&
      this.props.msgPW !== pP.msgPW &&
      this.state.msgPWDisplay === false
    ) {
      this.setState({ msgPWDisplay: true });
    }
    if (pP.data.userdata !== this.props.data.userdata) {
      let profession = "";
      let professionAutre = "";
      if (
        this.props.data.userdata.pro &&
        this.props.data.userdata.proLabel !== undefined
      ) {
        if (
          this.props.data.userdata.proLabel !== null &&
          this.props.data.userdata.proLabel !== `Médecin` &&
          this.props.data.userdata.proLabel !== `Chirurgien-Dentiste` &&
          this.props.data.userdata.proLabel !== `Pharmacien` &&
          this.props.data.userdata.proLabel !== `Sage-Femme` &&
          this.props.data.userdata.proLabel !== `Autre`
        ) {
          profession = "Autre";
          professionAutre = this.props.data.userdata.proLabel;
        } else {
          profession = this.props.data.userdata.proLabel;
        }
      }
      this.setState({
        data: {
          ...this.state.data,
          connected:
            this.props.data.connected !== undefined
              ? this.props.data.connected
              : false,
          email:
            this.props.data.userdata.email !== undefined
              ? this.props.data.userdata.email
              : "",
          abonnement:
            this.props.data.userdata.periode !== undefined
              ? this.props.data.userdata.periode
              : "",
          dateFin:
            this.props.data.userdata.dateFin !== undefined
              ? this.props.data.userdata.dateFin
              : "",
          premium:
            this.props.data.userdata.dateFin !== undefined &&
            this.props.data.userdata.dateFin !== null
              ? moment(this.props.data.userdata.dateFin).isSameOrAfter()
              : false,
          pro:
            this.props.data.userdata.pro !== undefined
              ? this.props.data.userdata.pro
              : false,
          cancelAt:
            this.props.data.userdata.cancelAt !== undefined
              ? this.props.data.userdata.cancelAt
              : "",
          profession,
          professionAutre,
          oldPW: "",
          newPW: "",
          newPWconfirm: "",
          declarationList:
            this.props.data.declarationList !== undefined
              ? this.props.data.declarationList
              : [],
        },
      });
    }
    if (pP.data.declarationList !== this.props.data.declarationList) {
      this.setState({
        data: {
          ...this.state.data,
          declarationList: this.props.data.declarationList,
        },
      });
    }
  }

  fetchSubscriptionPrice() {
    getProductPrices()
      .then((response) => {
        this.setState({
          subscriptionDetails: {
            yearly: {
              price: response.data.yearly.price,
              currency: response.data.yearly.currency,
            },
            monthly: {
              price: response.data.monthly.price,
              currency: response.data.monthly.currency,
            },
          },
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  fetchUserCardDetail() {
    getUserCardDetail().then((response) => {
      this.setState({
        userCardDetail: response.data,
      });
    });
  }

  handleChangeDropdown(event_) {
    const { data } = this.state;
    if (event_.target.value === "Autre") {
      this.setState({
        data: { ...data, profession: event_.target.value, professionAutre: "" },
      });
    } else {
      this.setState({
        data: {
          ...data,
          profession: event_.target.value,
          professionAutre: null,
        },
      });
    }
  }

  handleChange(event_) {
    const { data } = this.state;
    switch (event_.target.id) {
      case "email":
        this.setState({
          data: { ...data, email: event_.target.value },
          msgDisplay: false,
          msgPWDisplay: false,
        });
        break;
      case "professionAutre":
        this.setState({
          data: { ...data, professionAutre: event_.target.value },
          msgDisplay: false,
          msgPWDisplay: false,
        });
        break;
      case "oldPW":
        this.setState({
          data: { ...data, oldPW: event_.target.value },
          msgDisplay: false,
          msgPWDisplay: false,
        });
        break;
      case "newPW":
        this.setState({
          data: { ...data, newPW: event_.target.value },
          msgDisplay: false,
          msgPWDisplay: false,
        });
        break;
      case "newPWconfirm":
        this.setState({
          data: { ...data, newPWconfirm: event_.target.value },
          msgDisplay: false,
          msgPWDisplay: false,
        });
        break;
      default:
        break;
    }
  }

  handlePassword() {
    this.setState({
      passwordUpdate: !this.state.passwordUpdate,
      data: {
        ...this.state.data,
        oldPW: "",
        newPW: "",
        newPWconfirm: "",
      },
      msgDisplay: false,
      msgPWDisplay: false,
    });
  }

  handlePage(page) {
    if (page > 0) {
      this.setState(
        { activePage: page, msgDisplay: false, msgPWDisplay: false },
        () => neutralizeBack(this.handleReturn)
      );
    }
  }

  handleReturn() {
    this.setState(
      { activePage: 0, msgDisplay: false, msgPWDisplay: false },
      revivalBack
    );
  }

  handleUnsubscribe() {
    this.setState({ displayUnsub: !this.state.displayUnsub });
  }

  handleReviveSubscription() {
    this.setState({
      displayReviveConfirmation: !this.state.displayReviveConfirmation,
    });
  }

  validateReviveSubscription() {
    this.props.handlerReviveSubscription();
    this.setState({ displayReviveConfirmation: false });
  }

  closePopup() {
    this.setState({ displayUnsub: false });
  }

  validateUnsubscribe() {
    this.props.handlerUnsubscribe();
    this.setState({ displayUnsub: false });
  }

  render() {
    const {
      data,
      activePage,
      passwordUpdate,
      roleUpdate,
      msgDisplay,
      msgPWDisplay,
      displayUnsub,
      displayReviveConfirmation,
      subscriptionDetails,
      userCardDetail,
      showPaymentDetails,
    } = this.state;
    moment.locale(i18n.language);
    const { t } = this.props;
    const monthlyPrice =
      subscriptionDetails.monthly.price || config.premium.monthly.price;
    const yearlyPrice =
      subscriptionDetails.yearly.price || config.premium.yearly.price;
    const monthlyCurrency =
      getCurrencySymbol(subscriptionDetails.monthly.currency) ||
      config.premium.monthly.currency;
    const yearlyCurrency =
      getCurrencySymbol(subscriptionDetails.yearly.currency) ||
      config.premium.yearly.currency;
    const isSubscribedToAnnual = data.premium && data.abonnement === "Annuel";
    const isSubscribedToMonthly = data.premium && data.abonnement === "Mensuel";
    const showAnnualPlan = !data.premium || isSubscribedToAnnual;
    const showMonthlyPlan = !data.premium || isSubscribedToMonthly;

    const cancelDate = new Date(data.cancelAt);
    const terminateDate = new Date(data.dateFin);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const cancelDateString = cancelDate.toLocaleDateString("en-US", options);
    const terminateDateString = terminateDate.toLocaleDateString(
      "en-US",
      options
    );

    const renderPage = () => {
      if (passwordUpdate) {
        return (
          <>
            <p className="homeSubTitle">
              {t("profile.myInformation.changePassword.title")}
            </p>
            <TextField
              id="oldPW"
              type="password"
              value={data.oldPW}
              onChange={this.handleChange}
              label={t("profile.myInformation.changePassword.currentPassword")}
            />
            <TextField
              id="newPW"
              type="password"
              value={data.newPW}
              onChange={this.handleChange}
              label={t("profile.myInformation.changePassword.newPassword")}
            />
            <TextField
              id="newPWconfirm"
              type="password"
              value={data.newPWconfirm}
              onChange={this.handleChange}
              label={t("profile.myInformation.changePassword.confirmPassword")}
              error={data.newPW !== data.newPWconfirm}
            />
            {msgPWDisplay ? <p>{this.props.msgPW}</p> : null}
            <Box className="bottomBtnQuestionnaire">
              <Button
                variant="outlined"
                color="darkgrey"
                sx={{ marginBottom: "128px" }}
                onClick={this.handlePassword}
              >
                {t("app:common.actions.back")}
              </Button>
              <Button
                variant="contained"
                color="blue"
                sx={{ marginBottom: "64px" }}
                disabled={
                  data.oldPW === "" ||
                  data.newPW === "" ||
                  data.newPW !== data.newPWconfirm
                }
                onClick={() => {
                  this.props.handlerUpdatePassword({
                    newPassword: data.newPW,
                    oldPassword: data.oldPW,
                  });
                  this.setState({
                    data: {
                      ...data,
                      oldPW: "",
                      newPW: "",
                      newPWconfirm: "",
                    },
                  });
                }}
              >
                {t("app:common.actions.update")}
              </Button>
            </Box>
          </>
        );
      } else if (roleUpdate) {
        return (
          <>
            {data.pro ? (
              <p>{t("profile.myInformation.changeAccountType.info")}</p>
            ) : (
              <>
                <TextField
                  fullWidth
                  id="profession"
                  select
                  value={data.profession}
                  onChange={this.handleChangeDropdown}
                  label={t("profile.myInformation.profession")}
                  sx={{ marginTop: "12px" }}
                >
                  <MenuItem value="Médecin">
                    {t("app:common.profession.doctor")}
                  </MenuItem>
                  <MenuItem value="Chirurgien-Dentiste">
                    {t("app:common.profession.dentist")}
                  </MenuItem>
                  <MenuItem value="Pharmacien">
                    {t("app:common.profession.pharmacist")}
                  </MenuItem>
                  <MenuItem value="Sage-Femme">
                    {t("app:common.profession.midwife")}
                  </MenuItem>
                  <MenuItem value="Autre">
                    {t("app:common.profession.other")}
                  </MenuItem>
                </TextField>
                {data.profession === "Autre" ? (
                  <TextField
                    fullWidth
                    id="professionAutre"
                    value={data.professionAutre}
                    onChange={this.handleChange}
                    label={<Trans>Précisez</Trans>}
                    sx={{ marginTop: "12px" }}
                  />
                ) : null}
              </>
            )}

            <Box className="bottomBtnQuestionnaire">
              <Button
                variant="outlined"
                color="darkgrey"
                sx={{ marginBottom: "128px" }}
                onClick={() => {
                  this.setState({
                    roleUpdate: !this.state.roleUpdate,
                  });
                }}
              >
                {t("app:common.actions.back")}
              </Button>
              {data.pro ? (
                <Button
                  variant="contained"
                  color="blue"
                  sx={{ marginBottom: "64px" }}
                  onClick={() => {
                    this.props.handlerUpdateAccount({
                      profession: "",
                      professionAutre: "",
                    });
                    this.setState({ roleUpdate: !this.state.roleUpdate });
                  }}
                >
                  {t("app:common.actions.update")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="blue"
                  sx={{ marginBottom: "64px" }}
                  onClick={() => {
                    this.props.handlerUpdateAccount({
                      profession: data.profession,
                      professionAutre: data.professionAutre,
                    });
                    this.setState({ roleUpdate: !this.state.roleUpdate });
                  }}
                  disabled={
                    !data.profession ||
                    (data.profession === "Autre" &&
                      (data.professionAutre === "" ||
                        data.professionAutre ===
                          this.props.data.userdata.proLabel))
                  }
                >
                  {t("app:common.actions.update")}
                </Button>
              )}
            </Box>
          </>
        );
      }
    };

    return (
      <>
        {activePage === 0 ? (
          <>
            <p className="homeSecondTitle">{t("profile.title")}</p>
            <Box
              sx={{ boxShadow: 1 }}
              className="profileBox"
              onClick={() => this.handlePage(1)}
            >
              <p>{t("profile.myInformation.title")}</p>
              <NavigateNextIcon color="grey" />
            </Box>
            <Box
              sx={{ boxShadow: 1, marginTop: "0.5em" }}
              className="profileBox"
              onClick={() => this.handlePage(2)}
            >
              <div>
                <>
                  <p className={data.premium ? "premiumProfileText" : ""}>
                    {t("profile.mySubscription.title")}
                  </p>
                  {data.premium ? (
                    <Box
                      color="#F46B45"
                      sx={{
                        fontSize: "12px",
                        paddingBottom: "8px",
                        fontWeight: "600",
                      }}
                    >
                      {t("app:common.premium")}
                    </Box>
                  ) : null}
                </>
              </div>
              <NavigateNextIcon color="grey" />
            </Box>
            {data.pro || data.premium ? (
              <Box
                sx={{ boxShadow: 1, marginTop: "0.5em" }}
                className="profileBox"
                onClick={() => this.handlePage(3)}
              >
                <p>{t("profile.declarationHistory.title")}</p>
                <NavigateNextIcon color="grey" />
              </Box>
            ) : null}

            <Grid
              className="signoutLink"
              onClick={this.props.handlerDeconnexion}
            >
              <Box className="bottomBtnFixed">
                <Button variant="contained" color="grey" sx={{ boxShadow: 0 }}>
                  <p>{t("app:common.logout")}</p>
                  <LogoutOutlinedIcon color="black" />
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            {activePage === 1 ? (
              <>
                <p className="homeSecondTitle">
                  {t("profile.myInformation.title")}
                </p>
                {!passwordUpdate && !roleUpdate ? (
                  <>
                    <TextField
                      id="email"
                      type="text"
                      value={data.email}
                      onChange={this.handleChange}
                      label={t("profile.myInformation.email")}
                    />
                    {data.pro ? (
                      <>
                        <TextField
                          fullWidth
                          id="profession"
                          select
                          value={data.profession}
                          onChange={this.handleChangeDropdown}
                          label={t("profile.myInformation.profession")}
                          sx={{ marginTop: "12px" }}
                        >
                          <MenuItem value="Médecin">
                            {t("app:common.profession.doctor")}
                          </MenuItem>
                          <MenuItem value="Chirurgien-Dentiste">
                            {t("app:common.profession.dentist")}
                          </MenuItem>
                          <MenuItem value="Pharmacien">
                            {t("app:common.profession.pharmacist")}
                          </MenuItem>
                          <MenuItem value="Sage-Femme">
                            {t("app:common.profession.midwife")}
                          </MenuItem>
                          <MenuItem value="Autre">
                            {t("app:common.profession.other")}
                          </MenuItem>
                        </TextField>
                        {data.profession === "Autre" ? (
                          <TextField
                            fullWidth
                            id="professionAutre"
                            value={data.professionAutre}
                            onChange={this.handleChange}
                            label={<Trans>Précisez</Trans>}
                            sx={{ marginTop: "12px" }}
                          />
                        ) : null}
                      </>
                    ) : null}
                    {data.pro ? (
                      <Box
                        sx={{
                          boxShadow: 0,
                          backgroundColor: "#F9F9F9",
                          marginTop: "0.5em",
                        }}
                        className="profileBox informationBox"
                        onClick={() => {
                          this.setState({
                            roleUpdate: !this.state.roleUpdate,
                          });
                        }}
                      >
                        <p>
                          {t(
                            "profile.myInformation.changeAccountType.userAccount"
                          )}
                        </p>
                        <NavigateNextIcon color="grey" />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          boxShadow: 0,
                          backgroundColor: "#F9F9F9",
                          marginTop: "0.5em",
                        }}
                        className="profileBox informationBox"
                        onClick={() => {
                          this.setState({
                            roleUpdate: !this.state.roleUpdate,
                          });
                        }}
                      >
                        <p>
                          {t(
                            "profile.myInformation.changeAccountType.healthProfessionalAccount"
                          )}
                        </p>
                        <NavigateNextIcon color="grey" />
                      </Box>
                    )}

                    <Box
                      sx={{
                        boxShadow: 0,
                        backgroundColor: "#F9F9F9",
                        marginTop: "0.5em",
                      }}
                      className="profileBox informationBox"
                      onClick={this.handlePassword}
                    >
                      <p>{t("profile.myInformation.changePassword.label")}</p>
                      <NavigateNextIcon color="grey" />
                    </Box>
                    {msgDisplay ? <p>{this.props.msg}</p> : null}

                    <Box className="bottomBtnQuestionnaire">
                      <Button
                        variant="outlined"
                        color="darkgrey"
                        sx={{ marginBottom: "128px" }}
                        onClick={this.handleReturn}
                      >
                        {t("app:common.actions.back")}
                      </Button>
                      <Button
                        variant="contained"
                        color="blue"
                        sx={{ marginBottom: "64px" }}
                        onClick={() =>
                          this.props.handlerUpdateUser({
                            email: data.email,
                            profession: data.profession,
                            professionAutre: data.professionAutre,
                          })
                        }
                        disabled={
                          data.email === this.props.data.userdata.email &&
                          (!data.pro ||
                            data.profession ===
                              this.props.data.userdata.proLabel ||
                            (data.profession === "Autre" &&
                              (data.professionAutre === "" ||
                                data.professionAutre ===
                                  this.props.data.userdata.proLabel)))
                        }
                      >
                        {t("app:common.actions.update")}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>{renderPage()}</>
                )}
              </>
            ) : (
              <>
                {activePage === 2 ? (
                  <>
                    <p className="homeSecondTitle">
                      {t("profile.mySubscription.title")}
                    </p>
                    {data.premium ? (
                      <Grid className="abonnementList">
                        {config.premium.yearly.enabled && showAnnualPlan && (
                          <div>
                            <Box
                              className={`abonnementScreenBox ${
                                isSubscribedToAnnual
                                  ? data.cancelAt !== null
                                    ? "subscription-termination"
                                    : "selectedAboBox"
                                  : ""
                              }`}
                            >
                              <Grid>
                                <p className="abonnementTitle">
                                  {t("profile.mySubscription.annual.label")}
                                </p>
                                <p className="abonnementText">
                                  <Trans
                                    i18nKey="profile:profile.mySubscription.annual.price"
                                    values={{
                                      year: yearlyPrice,
                                      currency: yearlyCurrency,
                                    }}
                                    components={{ 1: <b></b> }}
                                  ></Trans>
                                </p>
                                {isSubscribedToAnnual && (
                                  <span className="subscription-end-info">
                                    {data.cancelAt !== null ? (
                                      <>
                                        <Trans
                                          i18nKey="profile:profile.mySubscription.subscriptionInfo"
                                          count={cancelDateString}
                                          components={{ 1: <strong></strong> }}
                                        ></Trans>
                                        {cancelDate > new Date() && (
                                          <span className="reviveSubscription">
                                            <Trans
                                              i18nKey="profile:profile.mySubscription.reviveSubscription.label"
                                              components={{
                                                1: (
                                                  <button
                                                    onClick={
                                                      this
                                                        .handleReviveSubscription
                                                    }
                                                    disabled={
                                                      this.props
                                                        .isReviveSubscriptionLoading
                                                    }
                                                  ></button>
                                                ),
                                              }}
                                            ></Trans>
                                            {this.props
                                              .isReviveSubscriptionLoading ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={15}
                                                sx={{ marginLeft: "0.5rem" }}
                                              />
                                            ) : null}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <Trans
                                        i18nKey="profile:profile.mySubscription.subscriptionRenewInfo"
                                        count={terminateDateString}
                                        components={{ 1: <strong></strong> }}
                                      ></Trans>
                                    )}
                                  </span>
                                )}
                                {isSubscribedToAnnual &&
                                userCardDetail.length > 0 ? (
                                  <>
                                    <span
                                      onClick={() => {
                                        this.setState({
                                          showPaymentDetails:
                                            !showPaymentDetails,
                                        });
                                      }}
                                      className="show-payment-icon"
                                    >
                                      {t(
                                        "profile.mySubscription.payment.title"
                                      )}
                                      {showPaymentDetails ? (
                                        <ExpandMoreIcon />
                                      ) : (
                                        <ChevronRightIcon />
                                      )}
                                    </span>
                                    {showPaymentDetails ? (
                                      <UserCardDetail
                                        userCardDetail={userCardDetail}
                                      />
                                    ) : null}
                                  </>
                                ) : null}
                                {!data.premium ? (
                                  <Button
                                    onClick={this.props.handlerCheckoutAnnual}
                                    variant="contained"
                                    color="blue"
                                    fullWidth
                                    sx={{
                                      textTransform: "unset",
                                      marginTop: "8px",
                                    }}
                                  >
                                    {t("profile.mySubscription.subscribe")}
                                  </Button>
                                ) : null}
                              </Grid>
                            </Box>
                          </div>
                        )}
                        {config.premium.monthly.enabled && showMonthlyPlan && (
                          <div>
                            <Box
                              className={`abonnementScreenBox ${
                                isSubscribedToMonthly
                                  ? data.cancelAt !== null
                                    ? "subscription-termination"
                                    : "selectedAboBox"
                                  : ""
                              }`}
                            >
                              <p className="abonnementTitle">
                                {t("profile.mySubscription.monthly.label")}
                              </p>
                              <p className="abonnementText">
                                <Trans
                                  i18nKey="profile:profile.mySubscription.monthly.price"
                                  values={{
                                    month: monthlyPrice,
                                    currency: monthlyCurrency,
                                  }}
                                  components={{ 1: <b></b> }}
                                ></Trans>
                              </p>
                              {isSubscribedToMonthly && (
                                <span className="subscription-end-info">
                                  {data.cancelAt !== null ? (
                                    <>
                                      <Trans
                                        i18nKey="profile:profile.mySubscription.subscriptionInfo"
                                        count={cancelDateString}
                                        components={{ 1: <strong></strong> }}
                                      ></Trans>
                                      {cancelDate > new Date() && (
                                        <span className="reviveSubscription">
                                          <Trans
                                            i18nKey="profile:profile.mySubscription.reviveSubscription.label"
                                            components={{
                                              1: (
                                                <button
                                                  onClick={
                                                    this
                                                      .handleReviveSubscription
                                                  }
                                                  disabled={
                                                    this.props
                                                      .isReviveSubscriptionLoading
                                                  }
                                                ></button>
                                              ),
                                            }}
                                          ></Trans>
                                          {this.props
                                            .isReviveSubscriptionLoading ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={15}
                                              sx={{ marginLeft: "1rem" }}
                                            />
                                          ) : null}
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <Trans
                                      i18nKey="profile:profile.mySubscription.subscriptionRenewInfo"
                                      count={terminateDateString}
                                      components={{ 1: <strong></strong> }}
                                    ></Trans>
                                  )}
                                </span>
                              )}
                              {isSubscribedToMonthly &&
                              userCardDetail.length > 0 ? (
                                <>
                                  <span
                                    onClick={() => {
                                      this.setState({
                                        showPaymentDetails: !showPaymentDetails,
                                      });
                                    }}
                                    className="show-payment-icon"
                                  >
                                    {t("profile.mySubscription.payment.title")}
                                    {showPaymentDetails ? (
                                      <ExpandMoreIcon />
                                    ) : (
                                      <ChevronRightIcon />
                                    )}
                                  </span>
                                  {showPaymentDetails ? (
                                    <UserCardDetail
                                      userCardDetail={userCardDetail}
                                    />
                                  ) : null}
                                </>
                              ) : null}
                              {!data.premium ? (
                                <Button
                                  onClick={this.props.handlerCheckoutMonthly}
                                  variant="contained"
                                  color="blue"
                                  fullWidth
                                  sx={{
                                    textTransform: "unset",
                                    marginTop: "8px",
                                  }}
                                >
                                  {t("profile.mySubscription.subscribe")}
                                </Button>
                              ) : null}
                            </Box>
                          </div>
                        )}
                      </Grid>
                    ) : (
                      <div className="subscription-message">
                        {t("profile.mySubscription.subtitle")}
                      </div>
                    )}
                    {displayUnsub === true ? (
                      <Popup
                        className="unsubPopup"
                        open={displayUnsub}
                        position="center"
                        onClose={() => this.closePopup()}
                      >
                        <div className="unsubPopupRow">
                          <p>
                            {t("profile.mySubscription.unsubscribe.title")}{" "}
                          </p>
                          <p onClick={this.handleUnsubscribe}>
                            <CrossIcon />
                          </p>
                        </div>
                        <p></p>
                        <div className="unsubPopupBtn">
                          <Button
                            onClick={this.handleUnsubscribe}
                            variant="outlined"
                            color="darkgrey"
                            sx={{ textTransform: "unset !important" }}
                          >
                            {t("profile.mySubscription.unsubscribe.no")}
                          </Button>
                          <Button
                            onClick={this.validateUnsubscribe}
                            variant="contained"
                            color="error"
                            sx={{ textTransform: "unset !important" }}
                          >
                            {t("profile.mySubscription.unsubscribe.yes")}
                          </Button>
                        </div>
                      </Popup>
                    ) : (
                      <></>
                    )}
                    {displayReviveConfirmation && (
                      <Popup
                        className="reviveSubscriptionPopup"
                        open={displayReviveConfirmation}
                        position="center"
                        onClose={() => {
                          this.setState({ displayReviveConfirmation: false });
                        }}
                      >
                        <div className="unsubPopupRow">
                          <p>
                            {t(
                              "profile.mySubscription.reviveSubscription.title"
                            )}{" "}
                          </p>
                          <p onClick={this.handleReviveSubscription}>
                            <CrossIcon />
                          </p>
                        </div>
                        <p></p>
                        <div className="unsubPopupBtn">
                          <Button
                            onClick={this.handleReviveSubscription}
                            variant="outlined"
                            color="darkgrey"
                            sx={{ textTransform: "unset !important" }}
                          >
                            {t("profile.mySubscription.reviveSubscription.no")}
                          </Button>
                          <Button
                            onClick={this.validateReviveSubscription}
                            variant="contained"
                            color="blue"
                            sx={{ textTransform: "unset !important" }}
                          >
                            {t("profile.mySubscription.reviveSubscription.yes")}
                          </Button>
                        </div>
                      </Popup>
                    )}
                    <Box className="bottomBtnQuestionnaire bottomBtnAbo">
                      {data.premium && data.cancelAt === null ? (
                        <Button
                          variant="text"
                          color="error"
                          disabled={
                            !data.premium || this.props.isUnsubscribeLoading
                          }
                          sx={{ marginBottom: "128px" }}
                          onClick={this.handleUnsubscribe}
                        >
                          {this.props.isUnsubscribeLoading ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              sx={{ display: "block", marginRight: "1rem" }}
                            />
                          ) : null}
                          {t("profile.mySubscription.unsubscribe.label")}
                        </Button>
                      ) : null}
                      {data.premium ? null : (
                        <Link to="/premium">
                          <Button
                            sx={{
                              color: "white",
                              background:
                                "linear-gradient(111.87deg, #F46B45, #EEA849)",
                              marginBottom: "128px",
                            }}
                            variant="contained"
                          >
                            {t("profile.mySubscription.subscribe")}
                          </Button>
                        </Link>
                      )}
                      <Button
                        variant="outlined"
                        color="darkgrey"
                        disabled={
                          this.props.isReviveSubscriptionLoading ||
                          this.props.isUnsubscribeLoading
                        }
                        sx={{ marginBottom: "64px" }}
                        onClick={this.handleReturn}
                      >
                        {t("app:common.actions.back")}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    {activePage === 3 ? (
                      <>
                        <p className="homeSecondTitle">
                          {t("profile.declarationHistory.title")}
                        </p>
                        <p>
                          <b>
                            {t("profile.declarationHistory.declarations", {
                              count: data.declarationList.length,
                            })}
                          </b>
                        </p>
                        {data.declarationList.map((v, i) => {
                          const displayDate = moment(v.date).format(
                            "Do MMMM YYYY"
                          );
                          return (
                            <Box
                              key={i}
                              sx={{ boxShadow: 1 }}
                              className="accueilBox"
                            >
                              <Grid>
                                <p className="accueilBoxDate">
                                  {displayDate.toString()}
                                </p>
                                <p className="accueilBoxType">
                                  <Trans>{v.type}</Trans>
                                </p>
                                <p className="accueilBoxProd">
                                  <Trans>{v.produit}</Trans>
                                </p>
                                <p className="accueilBoxProd">
                                  <Trans>{v.patient}</Trans>
                                </p>
                              </Grid>
                              <p>{v.nomProduit}</p>
                            </Box>
                          );
                        })}
                        <Button
                          variant="outlined"
                          color="darkgrey"
                          sx={{ marginBottom: "64px", padding: "12px" }}
                          onClick={this.handleReturn}
                        >
                          {t("app:common.actions.back")}
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default withTranslation("profile")(Profile);
