export const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  appBuild: process.env.REACT_APP_BUILD,
  appVersion: process.env.REACT_APP_VERSION,
  nodeEnv: process.env.NODE_ENV,
  port: process.env.PORT,
  premium: {
    monthly: {
      enabled:
        process.env.REACT_APP_FEATURE_MONTHLY_PREMIUM_ENABLED !== "false",
      price: process.env.REACT_APP_FEATURE_MONTHLY_PREMIUM_PRICE || 3.99,
      currency: process.env.REACT_APP_FEATURE_MONTHLY_PREMIUM_CURRENCY || "eur",
    },
    yearly: {
      enabled: process.env.REACT_APP_FEATURE_YEARLY_PREMIUM_ENABLED !== "false",
      price: process.env.REACT_APP_FEATURE_YEARLY_PREMIUM_PRICE || 39.99,
      currency: process.env.REACT_APP_FEATURE_YEARLY_PREMIUM_CURRENCY || "eur",
    },
  },
  publicUrl: process.env.PUBLIC_URL,
};
