import axios from "axios";

import { config } from "../config";

const instance = axios.create({
  withCredentials: true,
});

const BASE_URL = config.apiBaseUrl;

export const loginUser = async (data) => {
  return instance.post(`${BASE_URL}/login`, data);
};

export const logoutUser = async () => {
  return instance.post(`${BASE_URL}/logout`);
};

export const signinUser = async (data) => {
  return instance.post(`${BASE_URL}/signin`, data);
};

export const getUser = async () => {
  return instance.get(`${BASE_URL}/user`);
};

export const updateUser = async (data) => {
  return instance.put(`${BASE_URL}/user`, data);
};

export const updatePassword = async (data) => {
  return instance.put(`${BASE_URL}/userpw`, data);
};

export const updateUserAccount = async (data) => {
  return instance.put(`${BASE_URL}/user-account`, data);
};

export const recoverPassword = async (data) => {
  return instance.post(`${BASE_URL}/recover-pw`, data);
};

export const updateRecoverPassword = async (data, token) => {
  return instance.post(`${BASE_URL}/recover-pw/${token}`, data);
};

export const validateToken = async (token) => {
  return instance.get(`${BASE_URL}/recover-pw/${token}`);
};

export const validateAccount = async (token) => {
  return instance.get(`${BASE_URL}/validate-account/${token}`);
};
